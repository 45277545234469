import { TYPE } from '../../constants'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
    }
  }

  getNormalizeMenuData(data) {
    const brandName = this.gaApp.services.brandzone.main.getBrandName()

    const { item } = data

    return {
      url: item.link,
      path: item.name,
      itemListName: `брендзона/${brandName}`,
    }
  }

  send(Builder, data) {
    new Builder(data).build().forEach((data, key) => {
      const metric = this.metrics[key]
      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}
