export default (context) => {
  // пытаемся найти инфу про вебвью
  const isWebview =
    context.$gaApp.route.query.webview ||
    context.$gaApp.cookies.get('ga-webview') ||
    context.$gaApp.services.app.browserStorage.local.get('ga-webview')

  // проверяем что это не поддомены
  const isMainDomain = window.location.host.split('.')[0] === 'goldapple'

  // подключаем плагин только в проде, не подключаем в webview
  if (!isWebview && !context.$gaApp.config.get('dev') && isMainDomain) {
    try {
      /**
       * В зависимости от домена, строим строку
       *
       * w-goldapple-ru
       * w-goldapple-by
       * w-goldapple-kz
       * w-goldapple-qa
       */
      const cid = `w-${window.location.host.split('.').join('-')}`

      window.gib.init({
        cid,
        backUrl: '/front/api/event',
        gafUrl: '//ru.id.facct.ru/id.html',
      })
    } catch (e) {
      console.log('ошибка инита gun')
    }
  }
}
