export const endpoints = {
  slots: () => ({
    url: `brandzone/slots`,
  }),

  slotsV2: () => ({
    url: `brandzone/slots-v2`,
  }),

  block: () => ({
    url: `brandzone/block`,
  }),

  landing: () => ({
    url: 'brandzone/landing',
  }),

  listing: () => ({
    url: 'catalog/products',
  }),

  category: () => ({
    url: 'catalog/category',
  }),

  redirect: () => ({
    url: 'catalog/redirect',
  }),
}
