import { sectionChunks } from '../utils'

export class ApiStreamsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // Листинг
  async fetchStreamsListing(params) {
    try {
      let response =
        await this.gaApp.repositories.articles.streams.fetchListing(params)

      const noDataOnPage =
        params.pagination.pageNumber !== 0 && !response.data.streams.length

      // Если для текущей страницы нет данных - грузим первую страницу
      if (noDataOnPage) {
        response = await this.fetchFirstPageForce(params)
      }

      return {
        ...response.data,
        items: response.data.streams,
      }
    } catch {
      return this.getEmpty()
    }
  }

  async fetchStreamsListingPreview(id) {
    try {
      const response =
        await this.gaApp.repositories.articles.streams.fetchListingPreview(id)
      return response.data
    } catch {
      return this.getEmpty()
    }
  }

  async fetchStreamsFilterListing(params) {
    try {
      const response =
        await this.gaApp.repositories.articles.streams.fetchFilterListing(
          params,
        )
      return response.data
    } catch {
      return this.getEmpty()
    }
  }

  // Статусы
  async fetchStreamsStatuses() {
    try {
      const response =
        await this.gaApp.repositories.articles.streams.fetchStatuses()
      return response.data
    } catch (error) {
      return { statuses: [] }
    }
  }

  // Инфо
  async fetchStreamInfoById(id) {
    const { data } =
      await this.gaApp.repositories.articles.streams.fetchStreamInfoById(id)

    await this.gaApp.stores.articles.stream.$patch(this.formatStreamData(data))
  }

  async fetchStreamInfoByUrl(url) {
    const { data } =
      await this.gaApp.repositories.articles.streams.fetchStreamInfoByUrl(url)

    await this.gaApp.stores.articles.stream.$patch(this.formatStreamData(data))
  }

  // Фильтры
  async fetchFilters() {
    try {
      const response =
        await this.gaApp.repositories.articles.streams.fetchListingFilters()

      return response.data
    } catch {
      return { filters: [] }
    }
  }

  // Просмотры
  async fetchStreamViewed({ captchaToken, id }) {
    try {
      await this.gaApp.repositories.articles.streams.fetchViewed({
        captchaToken,
        id,
      })
    } catch (e) {
      console.error(e.message)
    }
  }

  async fetchStreamViewedWebview({ id }) {
    try {
      await this.gaApp.repositories.articles.streams.fetchViewedWebview({ id })
    } catch (e) {
      console.error(e.message)
    }
  }

  // Грузим данные для первой страницы
  fetchFirstPageForce(params) {
    this.gaApp.services.articles.list.setCurrentPageNumber(0)

    return this.gaApp.repositories.articles.streams.fetchListing({
      ...params,
      pagination: {
        pageNumber: 0,
        pageSize: params.pagination.pageSize,
      },
    })
  }

  // Преобразуем данные от БФФ
  formatStreamData(data) {
    return {
      ...data,
      listing: {
        skus: data.listingSkus || [],
      },
      sections: data.sections?.map((section) => ({
        ...section,
        chunks: sectionChunks(section),
      })),
    }
  }

  // Пустой объект
  getEmpty() {
    return {
      currentPage: 0,
      totalCount: 0,
      articles: [],
    }
  }
}
