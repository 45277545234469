import { useContext } from '@nuxtjs/composition-api'

import { onBeforeUnmount } from 'vue'

import { getId } from '@ga/utils'

import { GaModalSwipe } from '@ga/ui-components/modal-swipe'

import { useModal } from '~/_base/use/modal'

import { useAppRouter } from '~/modules/app'

// @vue/component
export default {
  name: 'ga-modal-swipe',

  components: {
    GaModalSwipeBase: GaModalSwipe,
  },

  inheritAttrs: false,

  model: {
    prop: 'opened',
    event: 'toggle',
  },

  props: {
    modalId: {
      type: String,
      default: null,
    },
    opened: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const { $gaApp } = useContext()
    const uniqueId = props.modalId ?? getId()

    const { zIndex, onStartOpen, onFinishClose, onLeaveCancelled } =
      useModal(uniqueId)

    // отключаем анимации при переходе по роуту
    const { routeChange } = useAppRouter()

    onBeforeUnmount(() => {
      $gaApp.services.modal.register.remove(uniqueId)
    })

    return {
      zIndex,
      onStartOpen,
      onFinishClose,
      onLeaveCancelled,
      routeChange,
    }
  },
}
