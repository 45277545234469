import { doubleRAF } from '@ga/shared-browser'

import { PRODUCT_LISTING_PAGE_SIZE } from '../constants'
import { STREAM_STATUS } from '../constants/stream'

export class StreamService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async fetchDataByUrl(url) {
    if (this.gaApp.stores.articles.stream.scrollToTop) {
      this.scrollToTop()
    }

    this.gaApp.stores.articles.one.isPreview = false
    await this.reset()

    await this.gaApp.services.articles.apiStreams.fetchStreamInfoByUrl(url)
    await this.updateStatus()

    this.fetchListingWidgetProducts(0)
  }

  async fetchDataById(id) {
    if (this.gaApp.stores.articles.stream.scrollToTop) {
      this.scrollToTop()
    }

    await this.reset()
    await this.gaApp.services.articles.apiStreams.fetchStreamInfoById(id)

    await this.updateStatus()
    this.fetchListingWidgetProducts(0)

    this.gaApp.stores.articles.one.isPreview = true
  }

  // Обновляем статус стрима
  // запрашиваем у АПИ список статусов, находим нужный стрим, меняем статус
  async updateStatus() {
    try {
      // Запрашиваем массив статусов от АПИ - [{id, statusType}]
      const { statuses } =
        await this.gaApp.services.articles.apiStreams.fetchStreamsStatuses()

      if (!statuses || !statuses.length) {
        return
      }

      // находим текущий стрим
      const newStatus = statuses.find(
        (status) =>
          status.externalId === this.gaApp.stores.articles.stream.externalId,
      )

      if (newStatus) {
        this.gaApp.stores.articles.stream.status = newStatus.statusType
        return
      }

      // Проверить по опциям
      this.gaApp.stores.articles.stream.status = this.getStatusFromOptions(
        this.gaApp.stores.articles.stream,
      )
    } catch (error) {
      console.log(error)
    }
  }

  getStatusFromOptions(stream) {
    let status = null

    if (stream.options.isActive) {
      status = STREAM_STATUS.LIVE
    }

    if (stream.options.isEnded) {
      status = STREAM_STATUS.ARCHIVE
    }

    if (!stream.options.isEnded && !stream.options.isActive) {
      status = STREAM_STATUS.SOON
    }

    return status
  }

  // Устанавливаем статус стрима в ручном режиме
  // Необходимо в момент когда таймер заканчивается и нужно сменить статус "скоро" на "лайв",
  // для того чтобы не слать дополнительный запрос за всеми статусами,
  // так как он будет выполнен немного позже. Запрос статусов выполняется раз в 30 секунд
  setStatus(statusType) {
    this.gaApp.stores.articles.stream.status = statusType
  }

  // перед загрузкой новых данных - поднимаем страницу наверх чтобы не было скачка контента
  scrollToTop() {
    doubleRAF().then(() => {
      window?.scrollTo?.({ top: 0 })
    })
  }

  async fetchListingWidgetProducts(pageNumber) {
    this.setListingLoading(pageNumber, true)

    try {
      await this.fetchBySku(pageNumber)
    } finally {
      this.setListingLoading(pageNumber, false)
    }
  }

  setListingLoading(pageNumber, value) {
    this.gaApp.stores.articles.stream.listing.pagination.show = value
    this.gaApp.stores.articles.stream.listing.pagination.pending = value

    if (!pageNumber) {
      this.gaApp.stores.articles.stream.listing.loading = value
    }
  }

  async fetchBySku(pageNumber) {
    const allSkus = this.gaApp.stores.articles.stream.listing.skus

    const startIndex = pageNumber * PRODUCT_LISTING_PAGE_SIZE
    const endIndex = startIndex + PRODUCT_LISTING_PAGE_SIZE

    const skusForLoad = allSkus.slice(startIndex, endIndex)

    const hasPage = this.gaApp.stores.articles.stream.listing.pages.some(
      (page) => page.number === pageNumber + 1,
    )

    if (!skusForLoad.length || hasPage) {
      return
    }

    const { productsRaw } =
      await this.gaApp.services.articles.one.fetchProductsBySku(skusForLoad)

    this.addPage(productsRaw, pageNumber)
  }

  addPage(productsRaw, pageNumber) {
    if (!productsRaw || !productsRaw.length) {
      return
    }

    this.gaApp.stores.articles.stream.listing.pages.push({
      items: productsRaw,
      number: pageNumber + 1,
    })

    this.gaApp.stores.articles.stream.listing.currentPageNumber = pageNumber + 1
  }

  // Отправляет запрос к АПИ на увеличение кол-ва просмотров
  // информации о стриме
  async fetchStreamViewed() {
    // Если это превью страница - не считаем просмотры
    if (this.gaApp.stores.articles.one.isPreview) {
      return
    }

    const id = this.gaApp.stores.articles.stream.id

    if (this.gaApp.isWebview) {
      return this.gaApp.services.articles.apiStreams.fetchStreamViewedWebview({
        id,
      })
    }

    const captchaToken = await this.gaApp.services.captcha.main.generateToken()

    await this.gaApp.services.articles.apiStreams.fetchStreamViewed({
      captchaToken,
      id,
    })
  }

  reset() {
    this.gaApp.stores.articles.stream.$reset()
  }
}
