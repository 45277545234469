export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * запрос данных для слотов
   */
  fetchSlots() {
    return this.gaApp.features.get('bzSlotsV2')
      ? this.fetchSlotsV2()
      : this.fetchSlotsV1()
  }

  /**
   * запрос данных для слотов v1
   */
  fetchSlotsV1() {
    try {
      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()

      const name = this.gaApp.stores.brandzone.page.meta.brandName
      const categoryId = this.gaApp.stores.brandzone.page.data.params.categoryId
      const customerGroupId = this.gaApp.stores.user.main.data.groupId

      return this.gaApp.repositories.brandzone.main.slots({
        name,
        path: this.gaApp.route.path,

        cityId,
        cityDistrict,
        geoPolygons,

        categoryId,

        ...(customerGroupId ? { customerGroupId } : {}),
      })
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  /**
   * запрос данных для слотов v2
   */
  fetchSlotsV2() {
    try {
      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()

      const name = this.gaApp.stores.brandzone.page.meta.brandName
      const categoryId = this.gaApp.stores.brandzone.page.data.params.categoryId
      const customerGroupId = this.gaApp.stores.user.main.data.groupId

      return this.gaApp.repositories.brandzone.main.slotsV2({
        name,
        path: this.gaApp.route.path,

        cityId,
        cityDistrict,
        geoPolygons,

        categoryId,

        ...(customerGroupId ? { customerGroupId } : {}),
      })
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  /**
   * запрос данных лендинга
   */
  fetchLanding() {
    try {
      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()

      const name = this.gaApp.stores.brandzone.page.meta.brandName
      const categoryId = this.gaApp.stores.brandzone.page.data.params.categoryId
      const customerGroupId = this.gaApp.stores.user.main.data.groupId

      return this.gaApp.repositories.brandzone.main.landing({
        name,
        path: this.gaApp.route.path,

        cityId,
        cityDistrict,
        geoPolygons,

        categoryId,

        ...(customerGroupId ? { customerGroupId } : {}),
      })
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  /**
   * Запрос листинга
   */
  fetchListing({ cityId, page = 1 }) {
    const { cityDistrict, geoPolygons } =
      this.gaApp.services.location.main.getDeliveryAddress()

    const categoryId = this.gaApp.stores.brandzone.listing.categoryId
    const customerGroupId = this.gaApp.stores.user.main.data.groupId

    return this.gaApp.repositories.brandzone.main.fetchProducts(
      {
        cityId,
        cityDistrict,
        geoPolygons,

        categoryId,

        filters: [],

        pageNumber: page,

        ...(customerGroupId ? { customerGroupId } : {}),
      },
      'listing',
    )
  }

  /**
   * Запрос категории
   */
  async fetchCategory() {
    const { params } = this.gaApp.stores.brandzone.page.data

    const response =
      await this.gaApp.repositories.brandzone.main.fetchCategory(params)

    this.gaApp.stores.brandzone.page.category = response.data
  }

  /**
   * Запрос данных редиректа по категории
   */
  async fetchCategoryRedirect(params) {
    const { data } =
      await this.gaApp.repositories.brandzone.main.fetchCategoryRedirect(params)

    return data
  }
}
