import { PRODUCT_LISTING_PAGE_SIZE } from '../../constants'
import { STREAM_STATUS } from '../../constants/stream'

export const state = () => {
  return {
    id: '',
    seo: [],

    status: STREAM_STATUS.SOON,

    translationDate: '',
    externalId: '',
    name: '',
    imageUrl: '',
    coverCaptionColor: 'white',
    tags: [],
    options: {
      isActive: false,
      isEnded: false,
      startDate: null,
      totalDuration: null,
    },
    sections: [],

    listing: {
      skus: [],
      link: null,
      currentPageNumber: 0,
      totalCount: PRODUCT_LISTING_PAGE_SIZE,

      pagination: {
        show: false,
        pending: false,
      },
      pages: [],
    },

    recommendations: [],

    isPreview: false,
    scrollToTop: false,
  }
}
