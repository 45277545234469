import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref } from 'vue'

import { GaIconAdditionalBackArrowModalV2 } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaButton } from '@ga/ui-components/button'
import { GaIcon } from '@ga/ui-components/icon'
import { GaImage } from '@ga/ui-components/image'
import { GaRadio } from '@ga/ui-components/radio'
import { GaRadioGroupBase } from '@ga/ui-components/radio-group-base'

import { GaModal } from '~/components/modal'

// @vue/component
export default {
  name: 'ga-app-country-switcher-modal',
  components: {
    GaModal,
    GaAction,
    GaButton,
    GaRadioGroupBase,
    GaRadio,
    GaImage,
    GaIcon,
    GaIconAdditionalBackArrowModalV2,
  },
  setup() {
    const { $gaApp } = useContext()

    const isDesktop = computed(() => $gaApp.mq('tablet-large+'))
    const isMobile = computed(() => $gaApp.mq('tablet-'))

    const opened = computed({
      get() {
        return $gaApp.stores.app.langSwitcher.countryModal.opened
      },
      set(value) {
        if (value) {
          $gaApp.services.app.langSwitcher.openCountryModal()
        } else {
          $gaApp.services.app.langSwitcher.closeCountryModal()
        }
      },
    })

    const additionalModalProps = computed(
      () => $gaApp.stores.app.langSwitcher.countryModal.props,
    )

    const modalProps = computed(() => ({
      fullwidth: !unref(isDesktop),
      fullheight: true,
      portalEnabled: false,
      noCloseAction: unref(isMobile),
      position: 'left',
      ...unref(additionalModalProps),
    }))

    const country = ref($gaApp.i18n.locale.country)

    const countryOptions = computed(() =>
      $gaApp.services.app.langSwitcher.allStores.map((store, index) => {
        return {
          id: index,
          label: store.text,
          flag: store.flag,
          value: store.code,
        }
      }),
    )

    const onBackClick = () => {
      opened.value = false
    }

    const onButtonClick = () => {
      if ($gaApp.services.app.langSwitcher.isCurrentStore(country.value)) {
        opened.value = false
      } else {
        $gaApp.services.app.langSwitcher.setSelectedCountry(country.value)
        $gaApp.services.app.langSwitcher.openConfirmModal(
          unref(additionalModalProps),
        )
      }
    }

    return {
      opened,

      isMobile,
      modalProps,

      country,
      countryOptions,

      onBackClick,
      onButtonClick,
    }
  },
}
