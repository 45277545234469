export class LangSwitcherService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get currentStore() {
    return this.gaApp.services.app.main.currentStoreContent
  }

  get allStores() {
    return this.gaApp.stores.app.common.convertedStores
  }

  get availableLanguages() {
    return this.gaApp.stores.app.common.convertedLanguages.filter(
      (lang) => lang.code !== this.gaApp.i18n.locale.code,
    )
  }

  get allLanguages() {
    return this.gaApp.stores.app.common.convertedLanguages
  }

  get currentLanguage() {
    return this.gaApp.stores.app.common.convertedLanguages.find(
      (lang) => lang.code === this.gaApp.i18n.locale.code,
    )
  }

  get hasAvailableLanguages() {
    return !!this.availableLanguages.length
  }

  isCurrentLanguage(languageCode) {
    return languageCode === this.currentLanguage.code
  }

  isStoreDefaultLanguage(languageCode, store) {
    return languageCode === store.languageDefaultCode
  }

  isCurrentStore(storeCode) {
    return storeCode === this.currentStore.code
  }

  // Достаем из мапы код языка, который соответствует коду в массиве languages
  getApiLanguageCode(code) {
    return this.gaApp.stores.app.common.initialLanguagesCodeMap[code] || code
  }

  switchLocale(code) {
    this.gaApp.i18n.setLocaleCookie(code)
    this.gaApp.eventBus.publish('module/app/lang-switcher/locale-changed', code)
  }

  openModal() {
    this.gaApp.stores.app.langSwitcher.modal.opened = true
  }

  closeModal() {
    this.gaApp.stores.app.langSwitcher.modal.opened = false
  }

  openCountryModal(props = {}) {
    this.gaApp.stores.app.langSwitcher.countryModal.props = props
    this.gaApp.stores.app.langSwitcher.countryModal.opened = true
  }

  closeCountryModal() {
    this.gaApp.stores.app.langSwitcher.countryModal.opened = false
  }

  openConfirmModal(props = {}) {
    this.gaApp.stores.app.langSwitcher.confirmModal.props = props
    this.gaApp.stores.app.langSwitcher.confirmModal.opened = true
  }

  closeConfirmModal() {
    this.gaApp.stores.app.langSwitcher.confirmModal.opened = false
  }

  showDropdown() {
    this.gaApp.stores.app.langSwitcher.dropdown.opened = true
  }

  hideDropdown() {
    this.gaApp.stores.app.langSwitcher.dropdown.opened = false
  }

  getEnrichedStoreDomain({ store, languageCode }) {
    const url = new URL(store.storeDomain)
    const selectedLanguage = languageCode ?? store.languageDefaultCode
    selectedLanguage && url.searchParams.append('language', selectedLanguage)

    return url.href
  }

  setSelectedStoreDomain({ store, languageCode }) {
    this.gaApp.stores.app.langSwitcher.selectedStoreDomain = store?.storeDomain
      ? this.getEnrichedStoreDomain({ store, languageCode })
      : '/'
  }

  setSelectedCountry(code) {
    this.gaApp.stores.app.langSwitcher.selectedCountry = code
    const selectedStore = this.gaApp.services.app.main.getStoreContent(code)
    this.setSelectedStoreDomain({ store: selectedStore })
  }

  redirectToSelectedStore() {
    window.location.href =
      this.gaApp.stores.app.langSwitcher.selectedStoreDomain
  }
}
